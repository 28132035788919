.mlaByCommunity {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  color: #3b4447;
  grid-area: content;
}

.mlaByCommunity div,
.mlaByCommunity h4 {
  margin: unset;
  padding: 10px;
}

.mlaByCommunity div {
  border-top: 1px solid #ddd;
}

.mlaByCommunity>div:nth-child(8n-1),
.mlaByCommunity>div:nth-child(8n-2),
.mlaByCommunity>div:nth-child(8n-3),
.mlaByCommunity>div:nth-child(8n) {
  background-color: #f9f9f9;
}

.pagination {
  width: 100%;
  justify-content: right;
  box-sizing: border-box;
  grid-area: pagination;
}

.mlaCommunityLetters {
  display: grid;
  grid-template-columns: repeat(14, max-content);
  align-items: center;
  justify-items: center;
  column-gap: 2px;
  grid-area: alphabet;
}

.mlaCommunityLetters>span {
  padding: 10px 15px 10px 15px;
  color: #434343;
  transition: color .25s ease-in-out, background-color .25s ease-in-out;
  -moz-transition: color .25s ease-in-out, background-color .25s ease-in-out;
  -webkit-transition: color .25s ease-in-out, background-color .25s ease-in-out;
  border-radius: 4px;
}

.mlaCommunityLetters>span:hover {
  text-decoration: none;
  background-color: #eee;
}

.mlaCommunityLetters>span.selected {
  background-color: #434343;
  color: white;
  cursor: pointer;
}

.mlaCommunityLetters>span.unselected {
  cursor: pointer;
}

.mlaByCommunityContainer {
  display: grid;
  grid-template-areas:
  'alphabet pagination'
  'content content';
}

/* For Mobile */
/* General style on screen size below 1080px */

@media screen and ( max-width: 1080px ) {
  .mlaByCommunityContainer {
    display: block;
  }

  .pagination {
    justify-content: left;
    margin-left: -30px;
  }
}

@media screen and ( min-width: 501px ) and ( max-width: 720px ) {
  .mlaCommunityLetters {
    grid-template-columns: repeat(10, max-content);
  }
}


/* Styling below are applied based on the screen sizes */

@media screen and ( min-width: 381px ) and ( max-width: 500px ) {
  .mlaCommunityLetters {
    grid-template-columns: repeat(8, max-content);
  }
}

@media screen and ( min-width: 200px ) and ( max-width: 380px ) {
  .mlaCommunityLetters {
    grid-template-columns: repeat(7, max-content);
  }
}

@media screen and ( min-width: 200px ) and ( max-width: 500px ) {
  .mlaByCommunity div,
  .mlaByCommunity h4 {
    padding: 5px;
    font-size: small;
  }

  .mlaCommunityLetters, .pagination {
    font-size: small;
  }
}
