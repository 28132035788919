.progerssOfBills {
  padding: 0 20px 30px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 25px;
  overflow-y: auto;
}

.progerssOfBills h1, .progerssOfBills h2 {
  text-transform: uppercase;
  color: black;
}

.progerssOfBills h1 {
  font-size: 30px;
}

.progerssOfBills h2 {
  font-size: 20px;
}

.pobDisclaimer {
  width: 100%;
  text-align: center;
}

.pobDisclaimer p {
  color: black;
  font-size: 20px;
  font-weight: 700;
}

.pobDisclaimer span {
  font-size: 17px;
}

.pobDisclaimer > div {
  padding: 10px 0;
  width: 100%;
  background-color: #f1f4f5;
  display: grid;
  gap: 2px;
  border-top: 2px solid #7c7c7b;
  border-bottom: 2px solid #7c7c7b;
}

.pobHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pobHeader h1 {
  margin: 0;
}

.pobHeader select {
  position: absolute;
  left: calc(50% + 200px);
  height: 40px;
  padding: 4px;
  border: 1px solid #e1e2e2;
  cursor: pointer;
}

.updateFlag {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media (max-width: 768px) {
  .pobHeader {
    flex-flow: column nowrap;
    gap: 10px;
  }

  .pobHeader select {
    position: relative;
    left: 0;
  }
}

.progerssOfBills .pobTableContainer {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.pobTableContainer > span {
  font-style: italic;
}

.pobTable {
  width: -webkit-fill-available;
  background-color: #f2f4f5;
  padding: 7px 15px 0px;
  overflow-y: auto;
  border-bottom: 3px solid #dBdcdc;
}

.pobTable table {
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
}

.pobTable th > div {
  padding: 4px 6px 3px 4px;
  text-align: left;
  color: #909090;
  font-weight: 500;
  cursor: pointer;
  text-wrap: balance;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 5px;
}

.pobTable tbody {
  border-top: 4px solid #dBdcdc;
}

.pobTable td {
  padding: 8px 3px 8px 6px;
}

.pobTable td > div {
  padding: 1px 0;
}

.pobTable .activeColumn {
  font-weight: bold;
  color: #00606B
}

.pobTable .billNum {
  min-width: 70px;
}

.pobTable .billNumRow {
  padding-left: 15px;
}

.pobTable .altRow {
  background-color: #fff;
}