.timePeriodSelect {
  padding: 10px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  margin-left: 10px;
  color: #9994a6;
}
.timePeriodLabel {
  font-size: 14px;
  margin-left: 10px;
}