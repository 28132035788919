.committeeNewsCard {
  max-width: 360px;
  padding: 10px;
  transition: background 0.45s;
}

.committeeNewsCard:hover { 
  background: #e0f0f6;
}

@media screen and (max-width: 768px) {
  .committeeNewsCard {
    max-width: 100%;
  }
}

.committeeContentNewsLinks {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 10px;
}