.hansardBills {
  padding: 0 20px 30px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 25px;
  overflow-y: auto;
}

.hansardBills h1, .hansardBills h2 {
  text-transform: uppercase;
  color: black;
}

.hansardBills h1 {
  font-size: 30px;
}

.hansardBills h2 {
  font-size: 20px;
}

.hbDisclaimer {
  width: 100%;
  text-align: center;
}

.hbDisclaimer p {
  color: black;
  font-size: 20px;
  font-weight: 700;
}

.hbDisclaimer span {
  font-size: 17px;
}

.hbDisclaimer > div {
  padding: 10px 0;
  width: 100%;
  background-color: #f1f4f5;
  display: grid;
  gap: 2px;
  border-top: 2px solid #7c7c7b;
  border-bottom: 2px solid #7c7c7b;
}

.hbHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hbHeader h1 {
  margin: 0;
}

.hbHeader select {
  position: absolute;
  left: calc(50% + 300px);
  height: 40px;
  padding: 4px;
  border: 1px solid #e1e2e2;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .hbHeader {
    flex-flow: column nowrap;
    gap: 10px;
  }

  .hbHeader select {
    position: relative;
    left: 0;
  }
}

.hansardBills .hbBillGroup {
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.hbBillGroup h2 {
  font-size: 24px;
}

.hbBillGroup > span {
  font-style: italic;
}

.hbBillGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  row-gap: 80px;
  column-gap: 40px;
}

@media screen and (max-width: 2400px) {
  .hbBillGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1900px) {
  .hbBillGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1400px) {
  .hbBillGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1090px) {
  .hbBillGrid {
    grid-template-columns: 1fr 1fr;
    row-gap: 60px;
    column-gap: 30px;
  }
}

@media screen and (max-width: 770px) {
  .hbBillGrid {
    justify-content: center;
    grid-template-columns: 1fr;
    row-gap: 40px;
  }
}
