.committeeSession {
  width: fit-content;
  margin-bottom: 0;
}


.committeeSession ul {
  padding: 0;
  display: flex;
  row-gap: 5px;
  flex-wrap: wrap;
}

.latestButton {
  padding: 10px;
  font-size: large;
}

.latestButton:hover {
  text-decoration: none;
  background-color: #eee;
}

.latestButton.active {
  background-color: #00606b;
  color: white;
  border-radius: 3px;
}