.mlaByConstituency {
  display: grid;
  grid-template-columns: 33% 1fr 25%;
}

.mlaByConstituency>div:nth-child(6n+1),
.mlaByConstituency>div:nth-child(6n+2),
.mlaByConstituency>div:nth-child(6n+3) {
  background-color: #f5f5f5;
}
.mlaByConstituency>div {
  display: flex;
  align-items: center;
  padding: 8px;
  border-top: 1px solid #ddd;
  color: #3b4447;
  
}
.mlaByConstituencyTitle {
  display: grid;
  grid-template-columns: 33% 1fr 25%;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
}

.mlaByConstituency .mlaPuck a {
  justify-items: baseline;
  column-gap: 10px;
  height: 75px;
  grid-template-columns: 20% 1fr;
}

.mlaByConstituency .mlaPuck>a>div {
  max-width: unset;
}
.mlaByConstituencyTitle>span {
  padding: 8px;
}

/* For Mobile */
/* General style on screen size below 900px */

@media screen and ( max-width: 900px ) {
  .mlaByConstituency .mlaPuck a {
    grid-template-columns: 30% 1fr;
  }

  .mlaByConstituencyTitle {
    grid-template-columns: 27% 1fr 25%;
  }

  .mlaByConstituency {
    grid-template-columns: 25% 1fr 25%;
  }
}

/* Styling below are applied based on the screen sizes */

@media screen and ( min-width: 200px ) and ( max-width: 420px ) {
  .mlaByConstituencyTitle, .mlaByConstituency {
    font-size: small;
  }

  .mlaByConstituency .mlaPuck a {
    grid-template-columns: 40% 1fr;
  }
}

@media screen and ( min-width: 421px ) and ( max-width: 900px ) {
  .mlaByConstituencyTitle, .mlaByConstituency {
    font-size: medium;
  }
}

@media screen and ( min-width: 200px ) and ( max-width: 550px ) {
  .mlaByConstituency .mlaPuck>a>div {
    max-width: min-content;
  }
}