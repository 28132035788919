.topBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box {
  display: flex;
  flex-flow: row wrap;
  column-gap: 10px;
  align-items: center;
  margin: 2px 5px 4px 0px;
}

.meetTime {
  color: #00606B;
  font-weight: 700;
}

.hansardTime {
  color: white;
  font-weight: 400;
  font-size: 0.8rem;
}

.hansardStatus {
  margin-left: 2px;
  padding: 2px;
  background: #2086e5;
}

.page {
  margin: 0px 5px 0px 5px;
}

.card {
  padding: 10px;
}

.card:hover {
  background: #d5eff4;
  transition-duration: 1s;
}

.plainColor {
  background-color: transparent; 
}

.greyColor {
  background-color: #f0f0f0; 
}

.committeeSection {
  width: fit-content;
  margin-bottom: 0;
}

.transcriptContent {
  display: grid;
  grid-template-columns: 100% 1fr;
}

.transcriptContent .light {
  color: #211f1f;
  font-weight: 500;
}

.details {
  color: #211f1f;
  font-weight: 400;
  font-size: 0.9rem;
}

.italicStyle {
  font-style: italic;
  padding: 5px 0px 5px 0px;
}

.city {
  font-weight: 700;
  color: #211f1f;
}

.transcript {
  margin-top: -5px;
}

.document li a {
  padding-left: 0;
  color: #434343;
  font-weight: 700;
}

.transcript li a {
  color: #434343;
  font-weight: 700;
}

.transcriptLink li a {
  color: #434343;
  font-weight: 700;
}

.container {
  display: flex;
}

.commCategory {
  flex: 1.5;
}

.transCategory {
  flex: 3;
}

.cardHeader button {
  float: right;
  border: none;
  background-color: transparent;
  color: #434343;
}

.iconStyle {
  color: #d0390f;
  margin-right: 2px;
}

.mark {
  color: #000;
  font-weight: 300;
}

.mediumFont {
  font-size: 1.75rem;
}

.largeFont {
  font-size: 2.5rem;
  margin: 2px 0px 2px 0px;
}

.meetingDetails {
  color: #211f1f;
  font-weight: 400;
  font-size: 1rem;
  margin: 30px 0px 0px 10px;
}

.docTitle {
  color: #00606B;
  margin: 30px 0px 15px 10px;
  font-size: 1.5rem;
}

.docLink {
  margin: 0px 0px 25px 10px;
}

.stringPadding {
  margin-left: 10px;
}

.rightDisplayed {
  text-align: right;
}

.rightDisplayed .pgButton {
  margin: 0px 5px 15px 0px;
  border: none;
  background-color: transparent;
  color: #00606B;
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
}

.pgButton:hover {
 text-decoration: underline;
}

.issueNumber {
  margin-left: 10px;
  font-size: 0.9rem;
}

.issueNumberFont {
  font-size: 0.9rem;
}


@media screen and ( max-width: 920px ) {
  .container, .transcriptContent {
    display: block;
  }
}