.recentDocuments {
  max-width: 555px;
  border: 1px solid #999;
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
}

.recentDocuments h3 {
  margin: 0px;
  padding: 5px 0;
  text-align: center;
  color: #fff;
  background-color: #00606B;
}

.recentDocuments > div {
  padding: 15px;
}