.debateBillCard {
  min-width: 250px;
  max-width: 450px;
  border-radius: 25px;
  box-shadow: 2px 2px 6px 4px #eee;
  overflow: hidden;
}

.debateBillCard > div {
  padding: 10px 35px;
}

.debateBillCard .dbcHeader {
  padding: 15px 25px;
  background-color: #3b4447;
}

.debateBillCard .dbcHeader h3 {
  margin: 0;
  text-transform: none;
  font-size: 22px;
  font-weight: 600;
  color: white;
}

.debateBillCard .dbcHeader a {
  color: white;
  font-size: 18px;
  cursor: pointer;
  text-wrap: balance;
}

.debateBillCard > div span {
  font-size: 18px;
  color: #4b5356;
  font-weight: 600;
}

.debateBillCard > div p {
  margin: 0;
  margin-bottom: 8px;
  font-size: 16px;
}

.dbcBody {
  display: flex;
  flex-flow: column nowrap;
  gap: 0px;
}

.dbcBody > div {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  gap: 25px;
}

.progressStep::before {
  content: '';
  position: absolute;
  top: 5px;
  bottom: -5px;
  left: 6px;
  width: 3px;
  background-color: #e0e0e0;
}

.progressStep.completed::before {
  background-color: #005f6a;
}

.progressStep.last::before {
  display: none;
}

.progressBullet {
  position: relative;
  margin-top: 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #e0e0e0; /* Grey for incomplete */
  transition: background-color 0.3s ease;
}

.progressStep.completed .progressBullet {
  background-color: #005f6a;
}

.dbcReadingEntry {
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
  gap: 1px;
  padding-bottom: 20px;
}

.dbcReadingEntry > span {
  font-size: 16px;
}

.dbcReadingEntry > a {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;
}
