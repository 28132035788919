.mlaPuck a {
  text-decoration: unset;
  color: #00606B;
  display: grid;
  grid-template-columns: 30% 1fr;
  align-items: center;
  justify-items: center;
  height:100%;
  padding-left: 10px;
}

.mlaPuck img {
  overflow: hidden;
}

.mlaPuck a:hover {
  text-decoration: underline;
}

.mlaPuck {
  color: #00606B;
  font-weight: 700;
  text-decoration: unset;
  width:100%;
  font-family: "BC Sans", sans-serif;
  font-weight: 300;
}

.mlaPuck img {
  height:75px;
  width:56px;
}

.mlaPuck div {
  max-width: 100px;
  line-height: 20px;
}

.findMyMla .mlaPuck {
  border: 1px solid #DDD;
  height: 97px;
}

.findMyMla .mlaList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  justify-content: center;
  row-gap: 10px;
  width: 98%;
  margin: auto;
}

.findMyMla .mlaHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 30px;
}

.findMyMla .mlaFooter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
}


.findMyMla {
  display: grid;
  grid-template-rows: max-content 1fr;
  row-gap: 20px;
  margin: 10px;
}

.findMyMla .postalCode {
  display: grid;
  grid-template-rows: 50% 50%;
  align-items: center;
}

/* For Mobile */
/* General style on screen size between 200px and 920px */

@media screen and ( min-width: 200px )  and ( max-width:920px ) {
  .findMyMla {
    margin: 15px;
    width:fit-content;
  }

  .findMyMla .mlaHeader {
    display: block;
    margin-bottom: 15px;
  }

  .findMyMla .postalCode {
    display: block;
    margin-bottom: 15px;
  }

  .findMyMla .mlaFooter {
    display: block;
    margin-bottom: 15px;
  }
}

/* Styling below are applied based on the screen sizes */

@media screen and ( min-width: 501px )  and ( max-width: 920px ) {
  .findMyMla {
    display: table;
    width: 96%;
  }
}

@media screen and (max-width: 500px) {
  .findMyMla .mlaList{
    display: block;
  }

  .mlaPuck {
    margin-bottom: 15px;
  }
}

@media screen and ( min-width: 501px )  and ( max-width: 720px ) {
  .findMyMla .mlaList{
    display: grid;
    grid-template-columns: 50% 50%;
    
  }
}

@media screen and ( min-width: 721px )  and ( max-width: 920px ) {
  .findMyMla .mlaList{
    display: grid;
    grid-template-columns: 33% 33% 33%;
  }
}
