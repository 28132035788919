.searchBoxButton svg {
  color: white;
  filter: invert(94%) sepia(42%) saturate(6527%) hue-rotate(179deg) brightness(75%) contrast(89%);
  height: 1rem;
  width: 1rem;
  cursor: pointer;
}

.searchBoxButton {
  background-color: unset;
  width: 2rem;
  cursor: pointer;
  padding: 0;
  margin: 0; 
  margin-left: -45px;
  border: unset;
}

.searchBoxInput {
  --border-opacity: 1;
  border: 1px solid #e2e8f0;
  border-width: 2px;
  border-radius: .5rem;
  width: 99%;
  margin-left: 0.5%;
  height: 100%;
  min-height: 30px;
}

.searchBox {
  width: 100%;
  height: 100%;
}

/* For Mobile */

@media screen and (max-width: 920px ) {
  .searchBoxInput {
    height: 40px;
  }
}
