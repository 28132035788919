.button {
  background-color: #005c66;
  border: 1px solid #434343;
  color: #FFF;
  height: 69px;
  font-size: 20px;
  min-width: 222px;
  transition: color .25s ease-in-out, background-color .25s ease-in-out;
  -moz-transition: color .25s ease-in-out, background-color .25s ease-in-out;
  -webkit-transition: color .25s ease-in-out, background-color .25s ease-in-out;
  border-radius: 4px;
  width: 100%;
}

.button:hover {
  background-color: #00606B;
  border-color: #434343;
  transition: color .25s ease-in-out, background-color .25s ease-in-out;
  -moz-transition: color .25s ease-in-out, background-color .25s ease-in-out;
  -webkit-transition: color .25s ease-in-out, background-color .25s ease-in-out;
  cursor: pointer;
}

/* For Mobile */

@media screen and (max-width: 920px) {
  .button {
    margin-bottom: 15px;
    margin-top: 15px;
  }
}
