.dropdownMenu {
  border: 1px solid #434343;
  padding: 5px 0;
  margin: 2px 0;
  text-align: left;
  border-radius: 4px;
  position: absolute;
  background: #fff;
  background-clip: padding-box;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 180px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  transition: opacity .25s ease-in-out, transform .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out, transform .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out, transform .25s ease-in-out;
}

.dropdownMenu.active{
  opacity: 1;
  transform: translateY(0);
}

.dropdownMenu.inactive{
  opacity: 0;
  transform: translateY(-20px);
  z-index: -1;
}

.listItem {
  list-style: none;
  left: 0;
  transition: background-color .25s linear;
}

.listItem  a {
  display: block;
  padding: 3px 2px;
  font-weight: normal;
  line-height: 1.3rem;
  color: #333;
  /* margin-left: 20px; */
  font-size: small;
  padding-left: 15px;
  padding-right: 15px;
  white-space: nowrap;
  transition: all .25s ease-in-out, transform .25s ease-in-out;
  -moz-transition: all .25s ease-in-out, transform .25s ease-in-out;
  -webkit-transition: all .25s ease-in-out, transform .25s ease-in-out;
}

.listItem:hover {
  background-color: #f5f5f5;
}

.selected {
  background-color: #00606b;
  color: white;
}

.selected a {
  color: white;
}

.selected:hover {
  background-color: #00606b;
}
