
.fileDisplay:nth-of-type(even) {
  background-color: #F5F5F5;
}

.fileDisplay {
  display: grid;
  grid-template-areas:
    "file-icon title"
    "empty1 name-link"
    "empty1 date"
    "empty1 pdf-link";
  grid-template-columns: max-content 1fr;
  column-gap: 10px;
  transition: background-color .25s ease-in-out;
  -moz-transition: background-color .25s ease-in-out;
  -webkit-transition: background-color .25s ease-in-out;
  padding: 10px;
}

.fileDisplay:hover {
  background-color: #e0f0f6;
}

.fileDisplay>a.fileIcon {
  grid-area: file-icon;
}

.fileDisplay>.fileTitle {
  grid-area: title;
}

.fileDisplay>.name {
  grid-area: name-link;
}

.fileDisplay>.date {
  grid-area: date;
}

.fileDisplay>.pdf {
  grid-area: pdf-link;
}