.committeeList {
  margin-left: -25px;
}

.committeeList ul {
  padding: 0;
}

.committeeList ul li a {
  padding-left: 20px;
}

.committeeList ul li {
  padding-left: 20px;
}
