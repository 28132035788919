.fileList {
  display: grid;
  grid-auto-flow: row;
  transition: background-color .25s ease-in-out;
  -moz-transition: background-color .25s ease-in-out;
  -webkit-transition: background-color.25s ease-in-out;
}
.fileList ul {
  justify-self: end;
}
.fileList>div {
  padding: 15px;
}
.fileList>div:nth-of-type(2n) {
 background-color: #F5F5F5;
}
.fileList>div:hover {
  background-color: #e0f0f6;
}
