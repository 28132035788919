h2 {      
  color: #00606B;
  margin: unset;
  font-family: "BC Sans", sans-serif;
}

h2.thin {
  font-weight: lighter;
}

h2.small {
  font-size: large;
}

/* For Mobile */

@media screen and (max-width: 920px) {
  h2{
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 900px) and (max-width: 999px) {
  h2{
    font-size: larger;
  }
}

