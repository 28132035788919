.committeeFileList {
  margin-top: 15px;
  display: flex;
  flex-flow: column nowrap;
}

.committeeFileList > div {
  padding: 15px;
}

.committeeFileList > :nth-child(odd) {
  background-color: #f0f0f0;
}

.box {
  display: inline-flex;
}

.section {
  margin: 5px 5px 4px 0px;
}

.iconStyle {
  color: #d0390f;
  margin-right: 2px;
}

.mark {
  color: #000;
  font-weight: 300;
}

.title {
  color: #00606B;
  font-weight: 700;
}