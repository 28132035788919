
.topBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box {
  display: flex;
  flex-flow: row wrap;
  column-gap: 10px;
  align-items: center;
  margin: 2px 5px 4px 0px;
}

.card {
  padding: 10px;
}

.card:hover {
  background: #d5eff4;
  transition-duration: 1s;
}

.city {
  font-weight: 700;
  color: #211f1f;
}

.italicStyle {
  font-style: italic;
  padding: 5px 0px 5px 0px;
}

.cardHeader button {
  float: right;
  border: none;
  background-color: transparent;
  color: #434343;
}

.details {
  color: #211f1f;
  font-weight: 400;
  font-size: 0.9rem;
}

.toggleButton {
  cursor: pointer;
}

.transcriptExpand {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.25s ease-out;
  -moz-transition: max-height 0.25s ease-out;
  -webkit-transition: max-height 0.25s ease-out;
}

.issueNumber {
  margin-left: 10px;
  font-size: 0.9rem;
}

@media (max-width: 600px) {
  .topBox {
    flex-direction: column;
    align-items: unset;
  }
}
