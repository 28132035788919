.billOverview {
  display: grid;
  grid-template-rows: max-content max-content;
  row-gap: 10px;
  padding-bottom: 15px;
}

.groupLinks {
  border-left: 1px solid #DEE0E3;
  border-right: 1px solid #DEE0E3;
}

.groupLinks>div {
  border-top: 1px solid #DEE0E3;
  padding: 15px;
  transition: background-color .25s ease-in-out;
  -moz-transition: background-color .25s ease-in-out;
  -webkit-transition: background-color .25s ease-in-out;
}

.groupLinks>div:hover {
  background-color: #f5f5f5;
}

.groupLinks>div>a {
  width: 100%;
  display: inline-block;
}

.groupLinks>div:last-child {
  border-bottom: 1px solid #DEE0E3;
}

.documentLinks>div {
  padding: 15px;
  transition: background-color .25s ease-in-out;
  -moz-transition: background-color .25s ease-in-out;
  -webkit-transition: background-color .25s ease-in-out;
}

.documentLinks>div:nth-child(2n) {
  background-color: #f4f5f5;
}

.documentLinks>div:hover {
  background-color: #e0f0f6;
}