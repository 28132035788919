.liveTag {
  background-color: #cc7a00;
  color: white;
  font-size: small;
  padding: 3px;
  border-radius: 2px;
}

.bluesTag {
  background-color: #2086e5;
  color: white;
  font-size: small;
  padding: 3px;
  border-radius: 2px;
}

.margin {
  margin-left: 5px;
}