.debateFileList>div:nth-child(even) {
  background-color: #f5f5f5;
}

.debateFileList>div:hover {
  background-color: #e0f0f6;
}

.debateFileList>div {
  transition: background-color .25s ease-in-out;
  -moz-transition: background-color .25s ease-in-out;
  -webkit-transition: background-color .25s ease-in-out;
}

.debateFileList {
  grid-area: files;
}

.hansardDebates {
  display: grid;
  grid-template-areas: 
  'pagination search'
  'files indexes'
  'files empty';
  grid-template-columns: 2fr 1fr;
  grid-template-rows: max-content max-content 1fr;
  column-gap: 20px;
  row-gap: 20px;
}

.searchBox {
  grid-area: search;
  height: max-content;
  align-self: center;
}

.pagination {
  justify-self: end;
}

.indexes {
  grid-area: indexes;
}

.hansardDebatesContainer {
  display: grid;
  grid-template-rows: max-content max-content max-content max-content;
  row-gap: 20px;
}

a.noBefore::before {
  content: '' !important; 
  /* temp */
}

a.noBefore {
  margin-bottom: 20px;
}

.subtitle {
  font-size: small;
}

/* Styling below are applied based on the screen size below 990px */
@media screen and ( max-width: 750px ) {
  .hansardDebates {
    grid-template-areas: 
    'search'
    'pagination'
    'files'
    'indexes';
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content 1fr max-content;
  }
}
