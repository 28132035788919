.editorContent ol ol {
  list-style-type: lower-latin;
}

.editorContent ol ol ol {
  list-style-type: lower-roman;
}

.editorContent ol ol ol ol {
  list-style-type: upper-latin;
}

.editorContent ol ol ol ol ol {
  list-style-type: upper-roman;
}

.editorContent {
  font-family: "BC Sans", sans-serif;
}
