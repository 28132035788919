.recentDocumentDay {
  margin-bottom: 20px;
}

.recentDocumentDay > h2 {
  margin-bottom: 20px;
}

.linkItem {
  margin-bottom: 20px;
}

.recentDocuments {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.recentDocumentStaticContent div, .recentDocumentStaticContent h2 {
  margin-bottom: 20px;
}

.recentDocumentHeader {
  margin-bottom: 20px;
}