.committeePuck {
  margin-top: 15px;
}

.puckTitle {
  margin-left:  -10px;
  font-weight: 900;
  font-size: 1.3rem;
}

.memberPuck {
  display: flex;
  margin-bottom: -5px;
  font-size: 1rem;
}

.memberPuck div:last-child {
  white-space: nowrap;
  color: #434343;
}