.table {
  border-collapse: collapse;
  margin-top: 10px;
  margin-left: 76px;
}

.cell {
  padding: 0px 5px 0px 5px; 
  min-width: 120px;
  box-sizing: border-box;
}

.row {
  display: grid;
  grid-template-columns: repeat(6, max-content);
  grid-gap: 8px; 
  margin-bottom: 30px;
}

.withBorder {
  border-left: 1px solid #000;
}

.cell li a {
  color: #434343;
  font-weight: 700;
  text-align: center;
}

.paliamentSpace {
  margin-bottom: 10px;
}

.parliament li a {
  margin-left: 75px;
  color: #434343;
  font-weight: 700;
}

.noBorder {
  border-left: none;
}

.notEmpty {
  margin-left: -15px;
}

.commName {
  color: #000;
  margin-left: 76px;
}

.commStack {
  margin-top: 15px;
}

.commInfo {
  color: #000;
  font-weight: 250;
  margin: 10px 0px 25px 76px;
}

.commType{
  margin-left: 40px;
}

@media screen and (min-width: 701px) and (max-width: 920px) {
  .row {
    grid-template-rows: repeat(2, max-content);
    grid-template-columns: repeat(4, max-content);
    grid-gap: 4px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 700px) {
  .row {
    grid-template-rows: repeat(3, max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 4px;
    margin-bottom: 30px;
  }
}