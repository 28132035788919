.searchItem {
  margin-bottom: 10px;
}

.searchBoxContainer {
  background-color: white;
  display: flex;
  padding: 5px;
  padding-left: 5px;
  align-items: center;
  padding-left: 20px;
  -webkit-box-shadow: 1px 4px 7px -3px rgba(0, 0, 0, 0.65);
  box-shadow: 1px 4px 7px -3px rgba(0, 0, 0, 0.65);
  margin: 10px;
  font-size: 16px;
}

.searchInput {
  width: 100%;
  height: 100%;
  border: unset;
  color: #9994a6;
  padding-right: 30px;
  padding-left: 10px;
  font-size: 16px;
}

.searchInput:focus {
  outline: none;
}

.searchIconContainer {
  cursor: pointer;
  background: transparent;
  border: unset;
}

.searchIconContainer svg {
  padding-right: 10px;
  color: #9691a4;
}

.searchButton {
  color: white;
  background-color: #005c66;
  font-weight: bold;
  padding: 15px 20px;
  padding-bottom: 15px;
  padding-bottom: 10px;
  border: unset;
  font-size: 16px;
  cursor: pointer;
}

.fileTypeSelect {
  padding: 10px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  margin-left: 10px;
  color: #9994a6;
}

.fileTypeLabel {
  font-size: 14px;
  margin-left: 10px;
}

.radio {
  accent-color: #005c66; 
}

.queryType {
  font-size: 14px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.resultsFound {
  margin-left: 20px;
}

.searchOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}