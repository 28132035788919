body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: museo-sans;
  src: url(https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff2"),
    url(https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff"),
    url(https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("opentype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: museo-sans;
  src: url(https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3) format("woff2"),
    url(https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3) format("woff"),
    url(https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3) format("opentype");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: museo-sans;
  src: url(https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff2"),
    url(https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff"),
    url(https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("opentype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: museo-sans;
  src: url(https://use.typekit.net/af/2841b6/00000000000000000000e806/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3) format("woff2"),
    url(https://use.typekit.net/af/2841b6/00000000000000000000e806/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3) format("woff"),
    url(https://use.typekit.net/af/2841b6/00000000000000000000e806/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3) format("opentype");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: museo-slab;
  src: url(https://use.typekit.net/af/53dec0/0000000000000000000100fe/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff2"),
    url(https://use.typekit.net/af/53dec0/0000000000000000000100fe/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("woff"),
    url(https://use.typekit.net/af/53dec0/0000000000000000000100fe/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3) format("opentype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: museo-slab;
  src: url(https://use.typekit.net/af/ea0e14/000000000000000000010141/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3) format("woff2"),
    url(https://use.typekit.net/af/ea0e14/000000000000000000010141/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3) format("woff"),
    url(https://use.typekit.net/af/ea0e14/000000000000000000010141/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3) format("opentype");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: museo-slab;
  src: url(https://use.typekit.net/af/c225e2/000000000000000000011aff/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff2"),
    url(https://use.typekit.net/af/c225e2/000000000000000000011aff/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff"),
    url(https://use.typekit.net/af/c225e2/000000000000000000011aff/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("opentype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: museo-slab;
  src: url(https://use.typekit.net/af/696cdf/00000000000000000000ec0a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3) format("woff2"),
    url(https://use.typekit.net/af/696cdf/00000000000000000000ec0a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3) format("woff"),
    url(https://use.typekit.net/af/696cdf/00000000000000000000ec0a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3) format("opentype");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-display: auto;
}

@font-face {
  font-family: BC Sans;
  src: url(/src/assets/fonts/2023_01_01_BCSans-Regular_2f.woff2) format("woff2"),
    url(/src/assets/fonts/2023_01_01_BCSans-Regular_2f.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: BC Sans;
  src: url(/src/assets/fonts/2023_01_01_BCSans-Bold_2f.woff2) format("woff2"),
    url(/src/assets/fonts/2023_01_01_BCSans-Bold_2f.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: BC Sans;
  src: url(/src/assets/fonts/2023_01_01_BCSans-Italic_2f.woff2) format("woff2"),
    url(/src/assets/fonts/2023_01_01_BCSans-Italic_2f.woff) format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: BC Sans;
  src: url(/src/assets/fonts/2023_01_01_BCSans-BoldItalic_2f.woff2) format("woff2"),
    url(/src/assets/fonts/2023_01_01_BCSans-BoldItalic_2f.woff) format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: BC Sans;
  src: url(/src/assets/fonts/2023_03_14_BCSans-Light_2g.woff2) format("woff2"),
    url(/src/assets/fonts/2023_03_14_BCSans-Light_2g.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: BC Sans;
  src: url(/src/assets/fonts/2023_01_01_BCSans-LightItalic_2f.woff2) format("woff2"),
    url(/src/assets/fonts/2023_01_01_BCSans-LightItalic_2f.woff) format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap
}

* {
  font-family: "BC Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "BC Sans", sans-serif;
  color: #00606B;
  font-weight: 700;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 20px;
  font-weight: 500;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #00606B;
  font-weight: 700;
  transition: color .25s ease-in-out, background-color .25s ease-in-out;
  -moz-transition: color .25s ease-in-out, background-color .25s ease-in-out;
  -webkit-transition: color .25s ease-in-out, background-color .25s ease-in-out;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  width: fit-content;
}

.pagination>li>a {
  color: #00606B;
  padding: 10px;
}

.pagination>.previous,
.pagination>.next {
  padding: 7px 10px;
  background-color: #fdfdfd;
  border: 1px solid #00606B;
  white-space: pre;
}

.pagination>.selected>a {
  color: gray;
  text-decoration: none;
}

.pagination>.next.disabled>a,
.pagination>.previous.disabled>a {
  color: gray;
  text-decoration: none;
}

.pagination>.next.disabled,
.pagination>.previous.disabled {
  border: 1px solid gray;
}

.pagination-small {
  margin: 10px;
  height: unset;
}

.pagination-small>.previous,
.pagination-small>.next {
  padding: 5px;
}

.strong-em em {
  font-weight: bold;
}



.text-small {
  font-size: .85em;
}

.text-tiny {
  font-size: .7em;
}

.text-big {
  font-size: 1.4em;
}

.text-huge {
  font-size: 1.8em;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}