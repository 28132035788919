.hansardDebateFile {
  display: grid;
  grid-template-areas: 
  "icon title"
  "empty links";
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content max-content;
  column-gap: 10px;
  padding: 15px;
  color: #3b4447;
}

.hansardDebateFile svg {
  filter: invert(51%) sepia(24%) saturate(1086%) hue-rotate(151deg) brightness(93%) contrast(94%);
  padding-top: 5px;
}

.debateLinks {
  grid-area: links;
  display: flex;
  flex-wrap: wrap;
  column-gap: 18px;
  font-size: 0.75em;
  row-gap: 5px;
}

.fileIcon {
  grid-area: icon;
}

.bluesTag {
  background-color: #2086e5;
  color: white;
  font-size: small;
  padding: 3px;
  border-radius: 2px;
}

.fileTitle {
  display: flex;
  column-gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 5px;
}

.liveTag {
  background-color: #cc7a00;
  color: white;
  font-size: small;
  padding: 3px;
  border-radius: 2px;
}

.subScript {
  font-size: 0.75em;;
  margin-top: 4px;
  color: #3b4447;
}