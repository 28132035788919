.side-nav {
  display: grid;
  grid-template-rows: max-content 1fr;
  border: 1px solid #b4b6b9;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.side-nav>.side-nav-header {
  background-color: #00606B;
  color: white;
  margin: unset;
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  padding: 15px;
}

.side-nav>.side-nav-content {
  display: grid;
  grid-template-columns: 1fr;
}

.with-padding {
  padding: 15px 25px;
}

.side-nav>.side-nav-content a {
  display: flex;
  justify-items: center;
  align-items: center;
  display: inline-block;
}

.side-nav>.side-nav-content a::before {
  content: "●";
  font-family: Arial;
  color: black;
  padding-right: 10px;
  align-self: baseline;
  padding-top: 5px;
}

.side-nav>.side-nav-content a::before:hover {
  text-decoration: none;
}