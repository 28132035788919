.nav {
  padding-left: 0;
  margin-bottom: 0;
  display: inline-block;
  padding: 10px;
  position: relative;
  cursor: pointer;
  background-color: #fff;
  transition: color .25s ease-in-out, background-color .25s ease-in-out;
  -moz-transition: color .25s ease-in-out, background-color .25s ease-in-out;
  -webkit-transition: color .25s ease-in-out, background-color .25s ease-in-out;
}

.nav:hover {
  background-color: #eee;
  border-radius: 4px;
  text-decoration: none;
}

.nav h3 {
  margin: 0;
  color: #434343;
}

.nav .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.nav.active {
  background-color: #eee;
  border-radius: 4px;
}

.committeeSession {
  width: fit-content;
  margin-bottom: 0;
}

.committeeAboutContent {
  display: grid;
  grid-template-columns: 2fr 6fr;
  column-gap: 30px;
}

.committeeAboutContent.hasSideBar {
  grid-template-columns: 2fr 3fr 3fr;
}

.committeeContent {
  display: grid;
  grid-template-columns: 3fr 5fr 3fr;
}

.committeeMembershipContent {
  display: grid;
  grid-template-columns: 100% 1fr;
}

.committeeContent .listItem,
.committeeMembershipContent .listItem {
  list-style: none;
}

.committeeContent .listItem a,
.committeeMembershipContent .listItem a {
  display: table;
  padding: 10px 4px 8px 40px;
  position: relative;
  font-size: 0.8em;
  border-bottom: 1px solid #eee;
  width: 80%;
  font-weight: 500;
}

.committeeContent .listItem a:hover {
  background: #00606B;
  color: #fff;
}

.committeeContent .info, 
.committeeMembershipContent .memberInfo  {
  margin-left: 20px;
  line-height: 1.5;
}

.committeeMembershipContent .memberInfo .first {
  margin-bottom: 30px;
  margin-top: 5px;
}

.committeeMembershipContent .memberInfo h2 {
  margin-bottom: 30px;
  margin-top: 40px;
}

.committeeMembershipContent .memberInfo {
  font-size: 0.9rem;
}

.committeeContent .info .committeeList, 
.committeeMembershipContent .memberInfo .committeeList {
  margin-top: -10px;
  margin-left: -25px;
}

.committeeContent .info .contact {
  margin-top: 25px;
}

.committeeContent .info .contact a{
  text-decoration: underline;
}

.committeeContent .memberTitle,
.committeeMembershipContent .memberTitle {
  color: #211f1f;
  font-weight: 500;
}

.committeeContent .memberList,
.committeeMembershipContent .memberList {
  margin-left: -45px;
  margin-top: 10px;
}

.committeeContent .memberPuck {
  display: flex;
  margin-bottom: 20px;
}

.committeeContent .memberPuck div:last-child {
  margin-left: 15px;
  white-space: nowrap;
  color: #434343;
}

.committeeContent .info .committeeClerk h3 {
  margin: 40px 0px 15px 0px;
  font-weight: 500;
}

.committeeContent .info .committeeClerk div {
  color: #211f1f;
  font-weight: 300;
  margin-left: 2px;
}

.committeeContent .info .committeeClerk .clerkInfo {
  margin-bottom: 20px;
}

.previousParliament {
  margin-top: 25px;
}

.plainColor {
  background-color: transparent; 
}

.greyColor {
  background-color: #f0f0f0; 
}

.navButton {
  border-top: 1px solid #DEE0E3;
  padding: 15px 15px 15px 0px;
  text-align: right;
}

.sideBar a::before {
  content: none !important;
}

.committeeContentNews {
  border: 1px solid #b4b6b9;
}

.committeeContentNews h2 {
  color: white;
  background: #00606B;
  text-align: center;
  padding: 5px;
}

.committeeContentNews h3 {
  margin: 0;
  font-weight: 500;
}

.committeeContentNewsList {
  padding: 15px
}

.committeeContentNewsList>div {
  padding: 15px;
  display: flex;
  flex-flow: column nowrap;
}

.committeeContentNewsList img {
  margin: 1em auto;
}

.committeeNews {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
}

.committeeNewsColumn {
  flex: 1 1 31.333333%;
  display: flex;
  flex-flow: column nowrap;
  min-width: 300px;
}

.committeeNewsColumn h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 10px;
  font-size: 22px;
}

.committeeNewsTranscript {
  max-width: 360px;
}

@media screen and (max-width: 768px) {
  .committeeNewsTranscript {
    max-width: 100%;
  }
}

@media screen and ( max-width: 920px ) {
  .committeeAboutContent, .committeeContent, .committeeTwoContent {
    display: block;
  }
}

.committeeDescription {
  margin-bottom: 20px;
  font-family: "museo-sans", sans-serif;
  font-size: 16px;
  color: #3b4447;
}

.mainNav ul {
  padding: 0;
}

.parliamentName {
  font-weight: 600;
  font-size: large;
  color: #434346;
}

.parliamentPuck {
  font-weight: 600;
  font-size: large;
  color: #434346;
}
.currentParliament.active,
.currentParliament {
  background-color: #00606b;
  color: white;
  border-radius: 3px;
}

.currentParliament:hover {
  background-color: #00606b;
}

.currentParliament span {
  color: white;
}
