.executiveCouncil {
  display: grid;
  grid-template-columns: 25% 1fr;
  color: #3b4447;
}

.executiveCouncil>.total,
.executiveCouncil h2,
.executiveCouncil a,
.executiveCouncil div {
  border-bottom: 1px solid #999999;
  border-right: 1px solid #999999;
  padding: 15px;
  margin: unset;
}

.executiveCouncil h2:first-child,
.executiveCouncil a:nth-child(2n-1),
.executiveCouncil div:nth-child(2n-1) {
  border-left: 1px solid #999999;
}

.executiveCouncil h2 {
  border-top: 1px solid #999999;
}

.executiveCouncil h4.total:nth-child(13) {
  border-left: 1px solid #999999;
}

.executiveCouncil a:nth-child(4n-1),
.executiveCouncil>div:nth-child(4n) {
  background-color: #d8d8d8;
}