.tab.selected {
  color: #555;
  cursor: default;
}

.tab {
  color: #434343;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: rgb(221, 221, 221);
  border-bottom-color: transparent;
  padding: 15px;
  width: max-content;
  transition: color .25s ease-in-out, background-color .25s ease-in-out;
  -moz-transition: color .25s ease-in-out, background-color .25s ease-in-out;
  -webkit-transition: color .25s ease-in-out, background-color .25s ease-in-out;
  font-size: 1.5em;
  font-weight: 700;
}

.tab:hover {
  border-color: #eee #eee #ddd;
  text-decoration: none;
  background-color: #eee;
}