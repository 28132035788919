.mlaBio .mlaHeader {
  display: grid;
  grid-template-columns: 25% 1fr;
}

.mlaBio {
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 30px;
}

.mlaBio .mlaHeader .mlaInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
}

.mlaBio .mlaAbout {
  white-space: pre-line;
  padding-top: 20px;
}

.mlaBio {
  color: #3b4447;
}

.mlaBio .labeledAddress {
  padding-bottom: 15px;
}

.labeledAddress h3 {
  margin-bottom: unset;
}

.mlaBio a {
  padding-bottom: 15px;
  display: block;
}

.mlaBio .sideBar {
  width: 90%;
  display: grid;
  grid-template-rows: max-content max-content;
  row-gap: 15px;
}

/* For Mobile */
/* General style on screen size below 1080px */

@media screen and ( max-width: 1080px ) {
  .mlaHeader img {
    width: 150px !important;
  }

  .mlaBio {
    display: block;
  }

  .mlaBio .sideBar {
    width: 100%;
    margin-top: 30px;
  }
}

/* Styling below are applied based on the screen size below 990px */

@media screen and ( max-width: 990px ) {
  .mlaBio .mlaHeader {
    display: block;
  }

  .mlaBio .mlaHeader .mlaInfo {
    display: block;
    margin: 15px 0px 15px 0px;
  }
}
