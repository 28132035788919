.committeeMeetings {
  display: grid;
  grid-template-rows: max-content 1fr;
  row-gap: 20px;
}

.sideNavContainer {
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 20px;
}

.meetingDayContainer {
  display: grid;
  grid-template-rows: max-content 1fr;
}

.dayMeetings {
  padding: 30px;
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
}

.dayMeetings div {
  border-top: 1px solid #e0e0e0;
  padding-top: 7px;
}

.otherMeetingDocuments {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.committeeMeetingContent {
  display: grid;
  grid-template-rows: max-content max-content max-content max-content;
  row-gap: 20px;
}

@media screen and (max-device-width: 992px) {
  .sideNavContainer {
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
  }
}