.dissolutionGrid {
  display: flex;
  flex-flow: row wrap;
  column-gap: 15px;
  row-gap: 10px;
  max-width: 925px;
  margin: 25px auto;
}

.dissolutionPuck {
  width: 218px;
  border: 1px solid #ddd;
}