.container {
  display: block;
}

.reportCategory {
  flex: 3;
}

.searchCategory {
  margin-bottom: 20px;
}

.searchCategory input {
  margin: 0;
}

.searchCategory h3 {
  margin-bottom: 10px;
}

.reportContent {
  display: grid;
  grid-template-columns: 100% 1fr;
}

.reportContent .light {
  color: #211f1f;
  font-weight: 500;
}

.page {
  margin: 0px 5px 0px 5px;
}

.card {
  padding: 10px;
}

.card:hover {
  background: #d5eff4;
  transition-duration: 1s;
}

.plainColor {
  background-color: transparent; 
}

.greyColor {
  background-color: #f0f0f0; 
}

.iconStyle {
  color: #d0390f;
  margin-right: 2px;
}

.box {
  display: inline-flex;
}

.section {
  margin: 5px 5px 4px 0px;
}

.title {
  color: #00606B;
  font-weight: 700;
}

.mark {
  color: #000;
  font-weight: 300;
}

@media screen and ( max-width: 920px ) {
  .container, .reportContent {
    display: block;
  }
}
