.partyStandings {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #3b4447;
}

.partyStandings h4 {
  color: #3b4447;
}

.partyStandings>.total:first-of-type {
  border-left: 1px solid #999999;
}

.partyStandings>.total,
.partyStandings h2,
.partyStandings div {
  border-bottom: 1px solid #999999;
  border-right: 1px solid #999999;
  padding: 15px;
  margin: unset;
}

.partyStandings h2:first-child,
.partyStandings div:nth-child(2n-1) {
  border-left: 1px solid #999999;
}

.partyStandings h2 {
  border-top: 1px solid #999999;
}

.partyStandings h4.total:nth-child(13) {
  border-left: 1px solid #999999;
}

.partyStandings>div:nth-child(4n-1),
.partyStandings>div:nth-child(4n) {
  background-color: #d8d8d8;
}