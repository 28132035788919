.listItem, .committeeItem {
  list-style: none;
  margin-top: 0px;
}

.listItem a {
  display: table;
  text-decoration: none;
  padding: 10px 4px 10px 40px;
  position: relative;
  font-size: 0.8em;
  border-bottom: 1px solid #eee;
  width: 80%;
  font-weight: 500;
}

.listItem li a {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.95em;
  font-weight: 400;
}

.listItem li a .icon {
  margin-right: 5px;
  font-weight: 800;
  font-size: large;
  color: #00606B;
}

.committeeItem a {
  display: table;
  text-decoration: none;
  padding: 10px 4px 10px 0px;
  position: relative;
  width: 80%;
}

.listItem a.inactiveLink:hover,
.committeeItem a.inactiveLink:hover {
  background: #eff3f7;
}

.listItem .activeLink, .listItem .activeLink .icon {
  background: #434343;
  color: #fff;
}

.listItem a.activeLink:hover {
  background: #434343;
  color: #fff;
}
